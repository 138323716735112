:root {
  --black: #000000;
  --white: #ffffff;
  --grey: #e1e1e1;
  --primary: #fe072e;
  --secondary: #f94f54;
  --font-size-base: 16px;
  --font-weight-bold: 700;
  --spacing-base: 8px;
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: var(--spacing-base) 0;
}

.layout-container {
  width: 100%;
}

p {
  font-size: var(--font-size-base);
}

h1 {
  font-size: calc(var(--font-size-base) * 2);
  color: var(--primary);
}

h2 {
  font-size: calc(var(--font-size-base) * 1.25);
  color: var(--primary);
  margin-bottom: calc(var(--spacing-base) * 2);
}

h3 {
  font-size: var(--font-size-base);
  margin-bottom: calc(var(--spacing-base) * 1.25);
}

a {
  font-weight: var(--font-weight-bold);
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  border-radius: calc(var(--spacing-base) * 0.5);
  color: var(--white);
  padding: calc(var(--spacing-base) * 0.75);
  text-decoration: none;
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    var(--secondary) 50%,
    var(--white) 50%
  );
  transition: background-position 0.4s;
}

a:hover {
  color: var(--secondary);
  background-position: -100% 0;
}

@media screen and (min-width: 900px) {
  .App {
    margin: calc(var(--spacing-base) * 5) 0;
  }

  .layout-container {
    width: 60%;
  }

  h1 {
    font-size: calc(var(--font-size-base) * 4);
  }

  h2 {
    font-size: calc(var(--font-size-base) * 2);
  }

  h3 {
    font-size: calc(var(--font-size-base) * 1.25);
  }

  p {
    font-size: calc(var(--font-size-base) * 1.5);
  }
}
