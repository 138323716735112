header {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: calc(var(--spacing-base) * 2);
}

header .heading {
  margin-bottom: calc(var(--spacing-base) * 2);
}

header .profile-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: calc(var(--spacing-base) * 4);
}

header .profile-box img {
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 3px 15px var(--black);
  margin-bottom: calc(var(--spacing-base) * 2);
}

header .links {
  background-color: var(--grey);
  border-radius: calc(var(--spacing-base) * 0.5);
  padding: calc(var(--spacing-base) * 2);
}

header .links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-base);
}

header .links ul li {
  margin: calc(var(--spacing-base) * 1.5) 0;
}

@media screen and (min-width: 900px) {
  header .profile-box img {
    width: 150px;
  }
}
