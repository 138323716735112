main {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: calc(var(--spacing-base) * 4) calc(var(--spacing-base) * 2);
  align-items: center;
  background-color: var(--white);
}

main .main-subsection {
  margin-bottom: calc(var(--spacing-base) * 4);
  padding: calc(var(--spacing-base) * 4) calc(var(--spacing-base) * 2);
  box-shadow: 0px 3px 15px var(--black);
  border-radius: calc(var(--spacing-base) * 0.5);
}

main .content-box {
  width: 100%;
  border-radius: calc(var(--spacing-base) * 0.5);
  margin-bottom: calc(var(--spacing-base) * 4);
  padding: calc(var(--spacing-base) * 2);
  background-color: var(--grey);
}

main .content-box:last-of-type {
  margin-bottom: 0;
}

main .content-box ul {
  padding-left: 0;
}

main .content-box ul li {
  list-style: none;
  margin: var(--spacing-base) 0;
}
