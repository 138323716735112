footer {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  align-items: center;
  padding: calc(var(--spacing-base) * 2);
}

footer .footer-content p {
  font-size: calc(var(--font-size-base) * 0.75);
  margin-bottom: calc(var(--spacing-base) * 2);
}
